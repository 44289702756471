import React, { useEffect } from 'react';
import {ReactComponent as Logo} from './logo.svg';
import './App.css';
import {gsap} from 'gsap';

function App() {
  const logoRef = React.createRef<SVGSVGElement>();
  const headingRef = React.createRef<HTMLHeadingElement>();
  const comingSoonRef = React.createRef<HTMLHeadingElement>();


  useEffect(() => {
    const tl = gsap.timeline();
    tl.addLabel("roll", '=1')
      .fromTo(logoRef.current, {scale: 1.20}, {scale: 1.30, duration: 1.5, ease: 'power1.Out'}, 'roll')
      .from(logoRef.current, {opacity: 0, duration: 1.5, ease: 'linear'}, 'roll')
      .addLabel('title', '=0')
      .to(logoRef.current, {scale: 1, duration: 1.5, ease: 'power1.inOut'}, 'title')
      .from(headingRef.current, {y: 50, duration: 1.5, ease: 'power1.inOut'}, "title")
      .fromTo(headingRef.current, {opacity: 0}, {opacity: 1, duration: 1, ease: 'power1.inOut'}, "=-1")
      .addLabel('comingSoon', '=1')
      .from(comingSoonRef.current, {y: 50, duration: 1.5, ease: 'power1.inOut'}, "comingSoon")
      .fromTo(comingSoonRef.current, {opacity: 0}, {opacity: 1, duration: 1, ease: 'power1.inOut'}, "=-1")

    return () => {
      tl.clear();
    }
  }, [comingSoonRef, headingRef, logoRef]);

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo-wrapper">
          <Logo ref={logoRef} className="App-logo" />
        </div>
        <h1 ref={headingRef}>
          Lazulin Apps
        </h1>
        <h2 ref={comingSoonRef}>
          🚧 In development 🚧
        </h2>
      </header>
    </div>
  );
}

export default App;
